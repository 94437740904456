import {arrayWrap} from './helpers';

export class Router {
    /**
     * @typedef {Object} Route
     * @property {string|Array} classes
     * @property {function} controller
     */
    /**
     * @param {Route[]} routes
     */
    constructor(routes) {
        this._routes = routes;
    }

    start() {
        const activeRoute = this._routes.find(route => {
            return arrayWrap(route.classes).some(c => document.body.classList.contains(c));
        });
        if (activeRoute) {
            new activeRoute.controller();
        }
    }
}
