import Chart from 'chart.js';

export class FinanceController {

    /**
     * @type {number|null}
     */
    lastHigh = null;

    /**
     * @type {Array}
     */
    data = [];

    /**
     * @type {Object<string, string>}
     */
    rangeIntervals = {
        '1d': '15m',
        '5d': '90m',
        '1mo': '1d',
        '3mo': '5d',
        '6mo': '5d',
        '1y': '1wk',
        '2y': '1mo',
        '5y': '3mo',
        '10y': '3mo',
        'ytd': '1wk',
    };

    /**
     * @type {Map<string, any>}
     */
    cache = new Map();

    activeRange = '1d';

    inError = false;

    constructor() {
        this.errorMessage = document.querySelector('.error-message');
        this.buttons = Array.from(document.querySelectorAll('.button-range'));
        this.highChartCanvas = document.querySelector('#high-chart');
        this.volumeChartCanvas = document.querySelector('#volume-chart');

        this.buttons.forEach(button => button.addEventListener('click', async () => {
            this.activeRange = button.dataset.range;

            this.data = await this.fetchChartData();
            this.render();
        }));

        (async () => {
            const [lastHigh, data] = await Promise.all([
                this.fetchLastHigh(),
                this.fetchChartData(),
            ]);
            this.lastHigh = lastHigh;
            this.data = data;
            this.render();
        })();
    }

    get activeInterval() {
        return this.rangeIntervals[this.activeRange];
    }

    get labels() {
        return this.data.map(({timestamp}) => {
            return new Date(timestamp * 1000).toLocaleString(undefined, {
                day: '2-digit',
                month: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            });
        });
    }

    render() {
        this.errorMessage.style.display = this.inError ? '' : 'none';

        this.buttons.forEach(button => button.classList.toggle('active', button.dataset.range === this.activeRange));

        this.drawHighChart(this.highChartCanvas);
        this.drawVolumeChart(this.volumeChartCanvas);
    }

    async fetchLastHigh() {
        this.inError = false;

        const startOfDay = new Date().setHours(0, 0, 0, 0) / 1000;
        const params = new URLSearchParams([
            ['action', 'finance_api'],
            ['nonce', window.Latecoere.nonce],
            ['endpoint', 'options'],
            ['params[0]', 'LAT.PA'],
            ['params[1]', startOfDay],
        ]);
        const response = await fetch(`${window.Latecoere.adminUrl}?${params}`);
        const {data} = await response.json();
        if (!response.ok) {
            this.inError = true;
            return null;
        }

        return data[0].quote.regularMarketPreviousClose;
    }

    async fetchChartData() {
        this.inError = false;

        if (this.cache.has(this.activeRange)) {
            return this.cache.get(this.activeRange);
        }

        const params = new URLSearchParams([
            ['action', 'finance_api'],
            ['nonce', window.Latecoere.nonce],
            ['endpoint', 'chart'],
            ['params[0]', 'LAT.PA'],
            ['params[1][range]', this.activeRange],
            ['params[1][interval]', this.activeInterval],
            ['params[1][region]', 'FR'],
            ['params[1][lang]', 'fr'],
        ]);

        const response = await fetch(`${window.Latecoere.adminUrl}?${params}`);
        const {data} = await response.json();
        if (!response.ok) {
            this.inError = true;
            this.cache.delete(this.activeRange);
            return [];
        }

        const charts = data;
        const quote = charts[0].indicators.quote[0];
        const timestamps = charts[0].timestamp;
        const chart = timestamps.map((timestamp, i) => {
            const high = quote.high[i];
            const volume = quote.volume[i];
            if (high !== null && volume !== null) {
                return {high, volume, timestamp};
            }
        }).filter(point => !!point);

        this.cache.set(this.activeRange, chart);

        return chart;
    }

    drawHighChart(canvas) {
        const data = {
            labels: this.labels,
            datasets: [
                {
                    label: 'Cours de l\'action',
                    fillColor: 'rgba(64, 189, 250, 0.33)',
                    strokeColor: '#40bdfa',
                    pointColor: '#40bdfa',
                    pointStrokeColor: '#ffffff',
                    pointHighlightFill: '#ffffff',
                    pointHighlightStroke: '#97bbcd',
                    data: this.data.map(({high}) => Math.round(high * 1000) / 1000),
                },
                {
                    label: 'Clôture précédente',
                    fillColor: 'rgba(0, 0, 0, 0)',
                    strokeColor: '#0b1b45',
                    pointColor: '#0b1b45',
                    pointStrokeColor: '#ffffff',
                    pointHighlightFill: '#ffffff',
                    pointHighlightStroke: '#97bbcd',
                    data: this.labels.map(() => this.lastHigh),
                },
            ],
        };
        this.drawChart('highChart', 'Line', canvas, data, {
            responsive: true,
            bezierCurve: false,
            multiTooltipTemplate: "<%= Number(value).toLocaleString(undefined, {minimumSignificantDigits: 3, maximumSignificantDigits: 3}) %>",
            scaleLabel: '<%= Number(value).toLocaleString(undefined, {minimumSignificantDigits: 3, maximumSignificantDigits: 3}) %>',
        });
    }

    drawVolumeChart(canvas) {
        const data = {
            labels: this.labels,
            datasets: [
                {
                    label: 'Volume',
                    fillColor: '#40bdfa',
                    strokeColor: 'rgba(151, 187, 205, 0.8)',
                    highlightFill: 'rgba(151, 187, 205, 0.75)',
                    highlightStroke: '#97bbcd',
                    data: this.data.map(({volume}) => volume),
                },
            ],
        };
        this.drawChart('volumeChart', 'Bar', canvas, data, {
            responsive: true,
            tooltipTemplate: '<% if (label) { %><%= label %> : <% } %><%= Number(value).toLocaleString() %>',
            scaleLabel: '<%= Number(value).toLocaleString() %>',
        });
    }

    drawChart(name, type, canvas, data, options) {
        if (this[name]) {
            this[name].destroy();
        }

        if (!this.data.length) {
            return;
        }

        this[name] = new Chart(canvas.getContext('2d'))[type](data, options);
    }
}
